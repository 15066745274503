<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import 'vue-material-design-icons/styles.css'

export default {

}
</script>

<style>
.hover-text-underline {
  color: #007bff;
}
.hover-text-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
